<template>
  <div class="project-progress-record" ref="listBox">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <van-tabs v-model="active" @click="onClick" sticky>
      <van-tab title="客户" name="a" style="overflow-y: auto;">
        <van-row style="background-color: #0084F8">
          <van-col span="24">
            <van-search v-model="selectCustomerVal" :focus="theFocus" custom-class="inp" placeholder="请输入客户名称"
                        show-action
                        @cancel="onCancel()" @search="init()">
            <div slot="action" @click="init">搜索</div>
            </van-search>
          </van-col>
        </van-row>
        <div v-if="this.list<=0">
          <van-empty image="error" description="没有数据"/>
        </div>
        <div v-else>
          <project-progress-cell :style="{marginBottom: index==list.length-1 ? '45px' : '0px'}"
                                 v-for="(item,index) in list" v-bind:key="item.id" :data="item" :commonIndex="index"
                                 @comIndex="comIndex(arguments)"></project-progress-cell>
        </div>
      </van-tab>
      <van-tab title="负责人" name="b">
        <van-row style="background-color: #0084F8">
          <van-col span="24">
            <van-search v-model="selectFzrVal" :focus="theFocus1"  placeholder="请输入负责人名称" show-action
                        @cancel="onCancel1()" @search="init1()">
            <div slot="action" @click="init1">搜索</div>
            </van-search>
          </van-col>
        </van-row>
        <div v-if="this.list1<=0">
          <van-empty image="error" description="没有数据"/>
        </div>
        <div v-else>
          <project-progress-fzr-cell :style="{marginBottom: index==list1.length-1 ? '45px' : '0px'}"
                                     v-for="(item,index) in list1" v-bind:key="item.id" :data="item"
                                     :commonIndex1="index"
                                     @comIndex1="comIndex1(arguments)"></project-progress-fzr-cell>
        </div>
      </van-tab>
    </van-tabs>
    <!-- 底部导航栏-->
    <crm-tab-bar :tabpage="5"/>
  </div>
</template>

<script>
import CrmTabBar from "@/components/CrmTabBar";
import ProjectProgressCell from "@/components/ProjectProgressCell";
import ProjectProgressFzrCell from "@/components/ProjectProgressFzrCell";
import {Toast} from "vant";

export default {
  name: "ProjectProgressRecord",
  components: {CrmTabBar, ProjectProgressCell, ProjectProgressFzrCell},
  data() {
    return {
      active: 0,
      list: [],
      list1: [],
      selectTitleNameValue: '',
      selectCustomerVal: '',
      selectFzrVal: '',
      theFocus: false,
      theFocus1: false,
      //列表高度
      freshHeight: 0,
      //下拉标识
      isLoading: false,
      //上拉标识
      loading: false,
      //上拉加载完成
      finished: false,
      scroll: 0,
    }
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight
    this.init();
  },
  watch: {
    //监听客户名头
    // 'selectCustomerVal': function () {
    //   this.list = [];
    //   this.init();
    // },
    // //监听负责人名头
    // 'selectFzrVal': function () {
    //   this.list1 = [];
    //   this.init1();
    // },
  },
  methods: {
    init() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      this.$ajax.post('/api/ajax/progress/getProgressRecordListByCustomer.json',
          {
            customerName: this.selectCustomerVal,
            curYear: '',
            curQuarter: '',
            curMonth: '',
            custom: '',
          }).then(res => {
        if (res.status == 200) {
          this.list = res.data
          Toast.success('加载成功');
        }
      }).catch(err => {
        console.log(err)
        Toast.fail('加载失败');
        this.$message.error('查询失败！');
      })
    },
    init1() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      this.$ajax.post('/api/ajax/progress/getProgressRecordListByFzr.json',
          {
            createdPeople: this.selectFzrVal,
            curYear: '',
            curQuarter: '',
            curMonth: '',
            custom: '',
          }).then(res => {
        if (res.status == 200) {
          this.list1 = res.data
          Toast.success('加载成功');
        }
      }).catch(err => {
        console.log(err)
        Toast.fail('加载失败');
        this.$message.error('查询失败！');
      })
    },
    // 下拉刷新
    onRefresh() {
      // setTimeout(() => {
      //     this.list = []
      //     for (let i = 0; i < 10; i++) {
      //         let data = {
      //             id: Math.random(),
      //             title: '哈尔滨拜访',
      //             date: '2020-12-12',
      //             user: '李阳',
      //             status:'未完成',
      //             to: 'ProjectProgressDetail',
      //         }
      //         this.list.push(data);
      //     }
      //
      //     // 刷新状态结束
      //     this.isLoading = false;
      //     this.finished = false
      // }, 1000);
    },
    // 上拉加载
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      // setTimeout(() => {
      //     for (let i = 0; i < 10; i++) {
      //         let data = {
      //             id: Math.random(),
      //             title: '哈尔滨拜访',
      //             date: '2020-12-12',
      //             user: '李阳',
      //             status:'已完成',
      //             to: 'ProjectProgressDetail',
      //         }
      //         this.list.push(data);
      //     }
      //
      // 加载状态结束
      this.loading = false;
      //
      //     // 数据全部加载完成
      //     if (this.list.length >= 40) {
      //         this.finished = true;
      //     }
      // }, 1000);
    },
    onCancel() {
      this.selectCustomerVal = ''
      this.init()
    },
    onCancel1() {
      this.selectFzrVal = ''
      this.init()
    },
    onClick(name, title) {
      console.log(name + "****" + title)
      if (name == 'b') {
        this.init1()
      }
      if (name == 'a') {
        this.init()
      }
    },
    comIndex(index) {
      console.log(index[0])
      this.list[index[0]].bulkCrmReplayDTO[1].use = !this.list[index[0]].bulkCrmReplayDTO[1].use
      this.list[index[0]].bulkCrmReplayDTO[1].moreBtn = true
    },
    comIndex1(index) {
      console.log(index[0])
      this.list1[index[0]].bulkCrmReplayDTO[1].use = !this.list1[index[0]].bulkCrmReplayDTO[1].use
      this.list1[index[0]].bulkCrmReplayDTO[1].moreBtn = true
    },
    //增加定位机制
    menu() {
      this.scroll = document.documentElement.scrollTop || document.body.scrollTop
      if (this.scroll == 0) {
        console.log(0)
      } else {
        this.$store.commit('COMMIT_SCROLL', this.scroll);
      }
    },
  }
}
</script>

<style scoped>
.project-progress-record {
  background-color: #FFFFFF;
}
</style>
<style>
.project-progress-record .van-tab {
  color: #FFF7FD;
  font-weight: 500;
  background-color: #0084F8;
}
</style>
